<template>
  <SectionBlock>
    <SectionTitle v-if="!titleDisabled" title="Legfrissebb híreink" />
    <SectionContent>
      <BRow gutterX="4" gutterY="4" alignH="center" alignV="stretch">
        <BCol
          sm="4"
          v-for="post in posts.slice(this.postsOffset, this.numberOfPosts === 0 ? posts.length : this.numberOfPosts)"
          :key="post.id"
        >
          <BlogPostCard :post="post" />
        </BCol>
      </BRow>
    </SectionContent>
    <SectionActionButtons
      v-if="!sectionActionButtonsDisabled && posts.length > 3"
      moreTo="/blog/"
      moreTitle="További bejegyzések"
    />
  </SectionBlock>
</template>
<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import BlogPostCard from "@/components/units/cards/BlogPostCard";
import SectionActionButtons from "@/components/layout/sections/parts/SectionActionButtons";

export default {
  name: "SectionBlog",
  components: { SectionBlock, SectionTitle, SectionContent, BlogPostCard, SectionActionButtons },
  props: {
    titleDisabled: {
      type: Boolean,
      default: false,
    },
    sectionActionButtonsDisabled: {
      type: Boolean,
      default: false,
    },
    postsOffset: {
      type: Number,
      default: 0,
    },
    numberOfPosts: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss"></style>
