<template>
  <div class="blog-post-card">
    <div class="blog-post-card-image">
      <img :src="require(`@/assets/blog/${post.image}`)" :alt="post.title" />
    </div>
    <div v-if="post.date.year || (post.date.month && post.date.day)" class="blog-post-card-date">
      <span v-if="post.date.year" class="blog-post-card-date-year">{{ post.date.year }}</span>
      <span v-if="post.date.month && post.date.day" class="blog-post-card-date-month-day"
        >{{ post.date.month }}.{{ post.date.day }}</span
      >
    </div>
    <div class="blog-post-card-content">
      <div class="blog-post-card-content-title">
        <h5>{{ post.title }}</h5>
      </div>
      <div class="blog-post-card-content-excerpt">
        <p v-html="post.excerpt"></p>
      </div>
      <div class="blog-post-card-content-action-buttons">
        <BtnMore
          :to="`/blog/${post.slug}`"
          :class="{ disabled: post.moreDisabled }"
          :animation="post.moreDisabled ? 'none' : 'cylon'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BtnMore from "@/components/units/buttons/BtnMore";

export default {
  name: "BlogPostCard",
  components: { BtnMore },
  props: {
    post: Object,
  },
};
</script>

<style scoped lang="scss">
.blog-post-card {
  position: relative;
  display: block;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  text-align: center;
  height: 100%;

  &-image {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: $card-image-max-height;

    img {
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  &-date {
    position: absolute;
    right: 0;
    top: $spacer;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    line-height: 1;
    background-color: rgba($primary, 0.8);
    border-radius: $border-radius 0 0 $border-radius;
    padding: calc(#{$spacer} / 2);

    &-year {
      @include set-font-style($font-size-level-4, $font-weight-bold, $white, uppercase);
    }

    &-month,
    &-month-day {
      @include set-font-style($font-size-level-5, $font-weight-bold, $white, uppercase);
    }

    &-day {
      @include set-font-style($font-size-level-5, $font-weight-bold, $white);
    }
  }

  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $spacer;
    padding: $spacer;

    &-title {
      position: relative;
      display: block;

      @include set-font-style($font-size-level-5, $font-weight-bold, $gray-900, uppercase);
    }

    &-excerpt {
      position: relative;
      display: block;

      @include set-font-style($font-size-level-4, $font-weight-light);
    }

    &-action-buttons {
      position: relative;
      display: block;
    }
  }
}
</style>
