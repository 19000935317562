<template>
  <PrimaryMenu />

  <Header title="Blog / Hírek" />

  <SectionBlock>
    <SectionTitle :title="post.title" />
    <SectionContent>
      <div v-if="!post.headerDisabled" class="post-image">
        <BImg :src="require(`@/assets/blog/${post.image}`)" :alt="post.title" rounded />
      </div>
      <div class="post-header">
        <div class="post-date">{{ post.date.year }}.{{ post.date.month }}.{{ post.date.day }}</div>
        <div class="post-share">
          <span>Megosztás:</span>
          <a :href="`https://facebook.com/sharer/sharer.php?u=` + this.currentUrl">
            <BootstrapIcon icon="facebook" />
          </a>
        </div>
      </div>
      <div class="post-content" v-html="post.content"></div>
    </SectionContent>
  </SectionBlock>

  <SectionBlog class="bg-light" numberOfPosts="3" titleDisabled />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionBlog from "@/components/layout/sections/SectionBlog";

export default {
  name: "BlogPost",
  title: "Blog",
  components: {
    SectionBlog,
    SectionContent,
    SectionTitle,
    SectionBlock,
    PrimaryMenu,
    Header,
    SectionContact,
    Footer,
  },
  data() {
    return {
      car: null,
    };
  },

  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.post = this.fetchBlogPost();
      }
    );

    this.post = this.fetchBlogPost();
  },

  methods: {
    fetchBlogPost() {
      let result = null;

      this.posts.map((post) => {
        if (post.slug === this.$route.params.slug) {
          result = post;
        }
      });

      // if (!result) {
      //   this.goTo("BlogPosts");
      // }

      return result;
    },
  },
};
</script>

<style lang="scss">
.post-header {
  display: flex;
  justify-content: space-between;
  margin: $spacer 0;
  padding-bottom: $spacer;
  @include set-font-style($font-size-level-3, $font-weight-light);
  border-bottom: $border-width $border-style $gray-300;
}

.post-date {
}

.post-share {
  display: flex;
  align-items: center;
  gap: $spacer;
}

.post-content picture img {
  margin: 0 0 1rem 0;
}
</style>
