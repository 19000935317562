<template>
  <div class="section-action-buttons">
    <BtnMore :to="moreTo" :title="moreTitle" class="btn-lg" />
  </div>
</template>
<script>
import BtnMore from "@/components/units/buttons/BtnMore";

export default {
  name: "SectionActionButtons",
  components: { BtnMore },

  props: {
    moreTo: {
      type: String,
      required: true,
    },
    moreTitle: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.section-action-buttons {
  margin-top: $spacer * 3;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: $spacer;
}
</style>
